const validacion = {
  title: 'Validación de Informe',
  list: 'Volver',
  view: {
    subtitleData: 'Datos del informe',
    subtitleFacturacion: 'Datos Facturación',
    customer: 'Cliente',
    noContacts: 'Cliente no tiene contactos',
    contact: 'Contacto',
    presupuesto: 'Presupuesto',
    agreement: 'Tipo Acuerdo',
    statusAccept: 'Estado informe',
    accept: 'Aceptar Informe',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'Ingreso creado exitosamente',
    docFactura: 'Factura',
    docInforme: 'Informe',
    folio: 'Folio Factura',
    amount: 'Monto $',
    fechaExp: 'Fecha Expiración',
    state: 'Estado',
    download: 'Descargar',
  },
};

export default validacion;
