import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, InputAdornment, makeStyles, Divider, LinearProgress, useMediaQuery,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import {
  useCustomers, useInformes, useAnalisis,
} from '@hooks';
import {
  Alert, ButtonAdorned, Autocomplete, Title, DataGrid, IconButton,
} from '@components/atoms';
import { createInformeSchema } from '@helpers/schemas';
import { CheckCircleRounded as CheckIcon, GetApp, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';
import { getDate } from '@helpers/utils';
import { useTheme } from '@material-ui/core/styles';

const Validacion = () => {
  const { pages: { employees: { validacion: { create: texts } } } } = i18n;
  const history = useHistory();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const { isAnalisisLoading } = useAnalisis();
  const {
    getInformes, getFiles, getFilesCert, isInformeLoading, informeError,
  } = useInformes();
  const [customers, setCustomers] = useState([]);
  const [analisis, setAnalisis] = useState([]);
  const [informe, setInforme] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const classes = useStyles();
  const theme = useTheme();

  const onlyxsSize = useMediaQuery(theme.breakpoints.only('xs'));

  const columnsSm = [
    {
      field: 'id',
      headerName: 'Código único', // texts.columns.customer,
      width: 250,
      valueGetter: (values) => values.row.data.id,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: 'Creado', // texts.columns.createdAt,
      align: 'center',
      type: 'date',
      valueFormatter: (values) => getDate(values.row.data.createdAt),
    },
    {
      field: 'file',
      headerAlign: 'center',
      width: 250,
      headerName: 'Documento', // texts.columns.createdAt,
      align: 'center',
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      valueFormatter: (value) => value.row.name,
    },
    {
      field: 'download',
      headerAlign: 'center',
      headerName: 'Descarga', // texts.columns.createdAt,
      align: 'center',
      width: 150,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      renderCell: (value) => (
        <IconButton onClick={() => getURLPDF(value)}>
          {' '}
          <GetApp />
          {' '}
        </IconButton>
      ),
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: 'Código único', // texts.columns.customer,
      flex: 1,
      valueGetter: (values) => values.row.data.id,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: 'Creado', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.row.data.createdAt),
    },
    {
      field: 'file',
      headerAlign: 'center',
      headerName: 'Documento', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      valueFormatter: (value) => value.row.name,
    },
    {
      field: 'download',
      headerAlign: 'center',
      headerName: 'Descarga', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      renderCell: (value) => (
        <IconButton onClick={() => getURLPDF(value)}>
          {' '}
          <GetApp />
          {' '}
        </IconButton>
      ),
    },
  ];

  const getURLPDF = async (value) => {
    const fileURL = await getFilesCert({ customerId: value.row.data.contact.customer.id, facturacionId: value.row.data.id, name: value.row.name });
    if (fileURL) {
      const t = await fetch(fileURL);
      const b = await t.blob();
      const blobURL = URL.createObjectURL(b);
      const fileLink = document.createElement('a');
      fileLink.href = blobURL;

      // it forces the name of the downloaded file
      fileLink.download = value.row.name;

      // triggers the click event
      fileLink.click();

      // window.open(blobURL);
    }
    return fileURL;
  };
  const form = useFormik({
    initialValues: {
      customer: undefined,
      analisis: undefined,
      agreement: '',
      amount: 0,
      file: [],
      docInforme: [],
      docInformeCliente: [],
    },
    enableReinitialize: true,
    validationSchema: createInformeSchema,
    validateOnChange: true,
    onSubmit: async () => {
    },
  });

  useEffect(async () => {
    setCustomers(await getCustomers());
  }, []);

  const onChangeCustomer = async (params, value) => {
    form.setFieldValue('docInforme', []);
    form.setFieldValue('customer', value);
    form.setFieldValue('contact', undefined);
    form.setFieldValue('analisis', undefined);
    setAnalisis([]);
    setInforme([]);
    if (value && value.id) {
      // const allAnalisis = await getAnalisisAll(`?step=completado&customer.id=${value.id}&archived=false`);
      const serviceFacturacion = await getInformes(`?contact.customer.id=${value.id}`);
      setAnalisis([...serviceFacturacion]);
    }
  };

  const handleChangeAnalisis = async (_, value) => {
    form.setFieldValue('docInforme', []);
    form.setFieldValue('analisis', value);
    form.setFieldValue('agreement', value.agreement);
    // const doc = await getFiles({ customerId: form.values.customer.id || undefined, analisisId: value.id, filesType: 'redaccion' });
    // form.setFieldValue('docInforme', doc);
    const doc = await getFiles({ customerId: value.contact.customer.id, facturacionId: value.id });
    form.setFieldValue('docInforme', doc);
    setInforme(doc.map((_files) => ({ ..._files, data: value })));
  };

  const getSelectedData = (rowIds) => {
    const selectedData = informe.filter((row) => rowIds.selectionModel.includes(row.id));
    setDataSelected(selectedData);
  };

  const downloadFiles = async (selectedFiles) => {
    selectedFiles.map(async (file) => {
      const fileURL = await getFilesCert({ customerId: file.data.contact.customer.id, facturacionId: file.data.id, name: file.name });
      if (fileURL) {
        const t = await fetch(fileURL);
        const b = await t.blob();
        const blobURL = URL.createObjectURL(b);
        const fileLink = document.createElement('a');
        fileLink.href = blobURL;

        // it forces the name of the downloaded file
        fileLink.download = file.name;

        // triggers the click event
        fileLink.click();

        // window.open(blobURL);
      }
      return fileURL;
    });
  };

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {informeError && <Alert>{informeError.message}</Alert>}
      {(isInformeLoading || isCustomerLoading || isAnalisisLoading) && <LinearProgress />}
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} sm={3} align="left" style={{ paddingBottom: '2rem' }}>
          <Title>{texts.title}</Title>
        </Grid>
        <Grid xs={12}>
          <Divider style={{ marginBottom: '1rem' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.customer}
              key={texts.customer}
              label={texts.customer}
              onChange={onChangeCustomer}
              defaultValue={form.values.customer}
              error={form.touched.customer && Boolean(form.errors.customer)}
              disabled={isCustomerLoading || isInformeLoading}
              helperText={form.touched.customer && form.errors.customer && form.errors.customer.id}
              items={customers}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.analisis}
              label={texts.analisis}
              key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
              onChange={handleChangeAnalisis}
              defaultValue={form.values.analisis}
              error={form.touched.analisis && Boolean(form.errors.analisis)}
              disabled={isCustomerLoading || isInformeLoading}
              helperText={(form.values.contact && analisis.length === 0 && !isInformeLoading && !isAnalisisLoading && texts.noAnalisis)
                || (form.touched.analisis && form.errors.analisis && form.errors.analisis.id && texts.noAnalisis)}
              items={analisis}
              getOptionLabel={(value) => `${value.analisis?.number}`}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          { form.values.analisis// .id
            && (
            <FormControl fullWidth>
              <TextField
                label={texts.agreement}
                variant="outlined"
                disabled
                value={form.values.analisis.agreement ? 'Con convenio' : 'Sin convenio'}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {(form.values.analisis.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ alignSelf: 'center' }}>
          { form.values.analisis// .id
            && (
            <FormControl fullWidth>
              <ButtonAdorned
                disabled={dataSelected.length === 0}
                onClick={() => downloadFiles(dataSelected)}
              >
                Descargar
              </ButtonAdorned>
            </FormControl>
            )}
        </Grid>
        { !isInformeLoading && informe.length !== 0
          && (
            <Grid item xs={12} style={{ width: '100%' }}>
              <DataGrid
                checkboxSelection
                onSelectionModelChange={(rowId) => getSelectedData(rowId)}
                columns={onlyxsSize ? columnsSm : columns}
                rows={informe}
              />
            </Grid>
          )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <ButtonAdorned color="default" onClick={() => history.push('/e/')}>
              {texts.list}
            </ButtonAdorned>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

Validacion.propTypes = {
};

Validacion.defaultProps = {
};

export default Validacion;
