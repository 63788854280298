import employees from './employees';
import customers from './customers';
import contacts from './contacts';
import presupuestos from './presupuestos';
import profile from './profile';
import analisis from './analisis';
import facturacion from './facturacion';
import informe from './informe';
import solicitudes from './solicitudes';
import validacion from './validacion';
import reporteria from './reporteria';
import dashboard from './dashboard';

export default {
  dashboard,
  facturacion,
  analisis,
  contacts,
  customers,
  employees,
  presupuestos,
  profile,
  informe,
  solicitudes,
  validacion,
  reporteria,
};
