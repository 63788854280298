import {
  Grid, FormControl, IconButton, makeStyles, Divider, LinearProgress,
} from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useInformes } from '@hooks';
import {
  Autocomplete,
  ButtonAdorned, DataGrid, Title,
} from '@components/atoms';
import { useFormik } from 'formik';
import { getDate } from '@helpers/utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import i18n from '@i18n';
import AuthContext from '@contexts/AuthContext';
import { Helmet } from 'react-helmet';

const ValidationPage = () => {
  const [informe, setInforme] = useState([]);
  const {
    isInformeLoading, getFiles, getInformes, getFilesCert,
  } = useInformes();
  const { pages: { customers: { validacion: texts } } } = i18n;
  const history = useHistory();
  const [facturacion, setFacturacion] = useState(null);
  const [dataSelected, setDataSelected] = useState([]);
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  useEffect(async () => {
    if (user) {
      form.setFieldValue('customer', user.customer);
      form.setFieldValue('contact', user.name);
    }
  }, [user]);

  const refreshFacturacion = async () => {
    const serviceFacturacion = await getInformes();
    setInforme([]);
    setFacturacion([...serviceFacturacion]);
  };

  useEffect(async () => {
    await refreshFacturacion();
  }, []);

  const columns = [
    {
      field: 'contact',
      headerName: 'Cliente', // texts.columns.customer,
      flex: 0.5,
      valueGetter: (values) => values.row.data.contact.customer.name,
    },
    {
      field: 'analisis',
      headerAlign: 'center',
      headerName: 'Informe', // texts.columns.number,
      align: 'center',
      flex: 0.4,
      valueGetter: (values) => values.row.data.analisis.number,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: 'Creado', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.row.data.createdAt),
    },
    {
      field: 'file',
      headerAlign: 'center',
      headerName: 'Documento', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      valueFormatter: (value) => value.row.name,
    },
    {
      field: 'download',
      headerAlign: 'center',
      headerName: 'Descarga', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      renderCell: (value) => (
        <IconButton onClick={() => getURLPDF(value)}>
          {' '}
          <GetAppIcon />
          {' '}
        </IconButton>
      ),
    },
  ];

  const getSelectedData = (rowIds) => {
    const selectedData = informe.filter((row) => rowIds.selectionModel.includes(row.id));
    setDataSelected(selectedData);
  };

  const getURLPDF = async (value) => {
    const fileURL = await getFilesCert({ customerId: value.row.data.contact.customer.id, facturacionId: value.row.data.id, name: value.row.name });
    if (fileURL) {
      const t = await fetch(fileURL);
      const b = await t.blob();
      const blobURL = URL.createObjectURL(b);
      const fileLink = document.createElement('a');
      fileLink.href = blobURL;

      // it forces the name of the downloaded file
      fileLink.download = value.row.name;

      // triggers the click event
      fileLink.click();

      // window.open(blobURL);
    }
    return fileURL;
  };

  const form = useFormik({
    initialValues: {
      customer: null,
      contact: null,
      informeSelected: null,
      docInforme: [],
    },
    enableReinitialize: true,
    // validationSchema: readInformeSchema,
    validateOnChange: true,
    onSubmit: async () => {

    },
  });

  const onChangeFacturacion = async (_, value) => {
    form.setFieldValue('informeSelected', value);
    if (value) {
      const doc = await getFiles({ customerId: value.contact.customer.id, facturacionId: value.id });
      form.setFieldValue('docInforme', doc);
      setInforme(doc.map((_files) => ({ ..._files, data: value })));
    }
  };

  const downloadFiles = async (selectedFiles) => {
    selectedFiles.map(async (file) => {
      const fileURL = await getFilesCert({ customerId: file.data.contact.customer.id, facturacionId: file.data.id, name: file.name });
      if (fileURL) {
        const t = await fetch(fileURL);
        const b = await t.blob();
        const blobURL = URL.createObjectURL(b);
        const fileLink = document.createElement('a');
        fileLink.href = blobURL;

        // it forces the name of the downloaded file
        fileLink.download = file.name;

        // triggers the click event
        fileLink.click();

        // window.open(blobURL);
      }
      return fileURL;
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      {(isInformeLoading) && <LinearProgress />}
      {facturacion && (
        <>

          <Grid container className={classes.container} direction="row" spacing={2}>
            {texts.title && (
            <Grid item xs={12} sm={3} align="left">
              <Title>{texts.title}</Title>
            </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
                  key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
                  defaultValue={form.values.informeSelected}
                  label="Informe"
                  onChange={onChangeFacturacion}
                  disabled={isInformeLoading}
                  items={facturacion}
                  getOptionLabel={(value) => `${value.analisis?.number}`}
                  close
                />
              </FormControl>

            </Grid>
            { !isInformeLoading && informe.length !== 0
          && (
            <Grid item xs={12} style={{ padding: 60 }}>
              <DataGrid
                checkboxSelection
                key="id"
                columns={columns}
                onSelectionModelChange={(rowId) => getSelectedData(rowId)}
                rows={informe}
              />
            </Grid>
          )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item>
              <ButtonAdorned
                color="default"
                onClick={() => history.push('/')}
              >
                {texts.list}
              </ButtonAdorned>
            </Grid>
            <Grid item>
              <ButtonAdorned
                color="primary"
                disabled={dataSelected.length === 0}
                onClick={() => downloadFiles(dataSelected)}
              >
                {texts.view.download}
              </ButtonAdorned>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

export default ValidationPage;
