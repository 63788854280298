import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServicePresupuestos(data = '') {
  try {
    const response = await API.get(`/presupuesto${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/presupuesto/', message: `Hubo un problema al intentar buscar presupuestos. ${message}` });
  }
}

export async function getServicePresupuesto(data) {
  try {
    const response = await API.get(`/presupuesto/${data.id}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/presupuesto/', message: `Hubo un problema al intentar buscar presupuesto. ${message}` });
  }
}

export async function postServicePresupuesto(data) {
  try {
    const response = await API.post('/presupuesto', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/presupuesto/', message: `Hubo un problema al intentar crear presupuesto. ${message}` });
  }
}

export async function putServicePresupuesto(data) {
  try {
    const response = await API.put('/presupuesto', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/presupuesto/', message: `Hubo un problema al intentar editar presupuesto. ${message}` });
  }
}

export async function getServicePresupuestoCount(data) {
  try {
    const {
      dateInit, dateEnd, all, archivados,
    } = data;
    const response = await API.get(`/presupuesto/d/count?dateInit=${dateInit}&dateEnd=${dateEnd}&all=${all}&archivados=${archivados}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/presupuesto/', message: `Hubo un problema al intentar buscar presupuesto. ${message}` });
  }
}
