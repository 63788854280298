import React, { useContext } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Card } from '@components/atoms';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthContext from '@contexts/AuthContext';

const WelcomeBoard = ({ facturacion, presupuesto, analisis }) => {
  const history = useHistory();
  const classes = useStyles();
  const { auth, user } = useContext(AuthContext);
  const { super_rol: superRol } = auth;
  const prefixURLs = {
    customer: 'c',
    employee: 'e',
  };

  return (
    <>

      <Grid container style={{ backgroundColor: '#eee' }}>
        <Grid container justifyContent="space-around">
          <Grid item sm={12} md={12}>
            <Box style={{
              backgroundColor: '#eee', padding: '0 1.5em 1em 1.5em', borderRadius: '5px',
            }}
            >
              <h2 className={classes.h2}>Bienvenido</h2>
              <h1 className={classes.h1}>{ user && `${user.name} ${user.lastname}` }</h1>
            </Box>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-around" style={{ padding: '0 1.5rem' }}>
          {presupuesto && presupuesto.data && (
          <Grid item sm={12} md={3}>
            <Card
              style={{ padding: '1em' }}
              title={presupuesto && presupuesto.name}
              count={presupuesto && presupuesto.data && presupuesto.data.total}
              color="#fff"
              onClick={() => history.push(`/${prefixURLs[superRol]}/presupuestos/`)}
            />
          </Grid>
          )}
          {analisis && analisis.data && (
          <Grid item sm={12} md={3}>
            <Card
              style={{ padding: '1em' }}
              title={analisis && analisis.name}
              count={analisis && analisis.data && analisis.data.total}
              color="#fff"
              onClick={() => history.push(`/${prefixURLs[superRol]}/analisis/`)}
            />
          </Grid>
          )}
          {facturacion && facturacion.data && (
          <Grid item sm={12} md={3}>
            <Card
              style={{ padding: '1em' }}
              title={facturacion && facturacion.name}
              count={facturacion && facturacion.data && facturacion.data.total}
              color="#fff"
              onClick={() => history.push(`/${prefixURLs[superRol]}/facturacion/`)}
            />
          </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

WelcomeBoard.propTypes = {
  analisis: PropTypes.shape({
    data: PropTypes.shape({
      total: PropTypes.number,
    }),
    name: PropTypes.string,
  }),
  facturacion: PropTypes.shape({
    data: PropTypes.shape({
      total: PropTypes.number,
    }),
    name: PropTypes.string,
  }),
  presupuesto: PropTypes.shape({
    data: PropTypes.shape({
      total: PropTypes.number,
    }),
    name: PropTypes.string,
  }),
};

WelcomeBoard.defaultProps = {
  analisis: undefined,
  facturacion: undefined,
  presupuesto: undefined,
};

const useStyles = makeStyles(() => ({
  h1: {
    fontSize: '2.5em',
    color: '#333',
    margin: '0',
  },
  h2: {
    fontSize: '1.7em',
    color: '#333',
    marginBottom: '.2rem',
  },
}));

export default WelcomeBoard;
