import * as validator from 'yup';

import i18n from '@i18n';

const createFilterDashboardSchema = validator.object({
  yearFrom: validator.object({
    id: validator.number().required(i18n.validationSchemas.yearFrom.required),
    name: validator.string(),
  }),
  yearTo: validator.object({
    id: validator.number().required(i18n.validationSchemas.yearTo.required),
    name: validator.string(),
  }),
});

export default createFilterDashboardSchema;
