import React, { useEffect, useState } from 'react';
import i18n from '@i18n';
import { LinearProgress, Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { usePresupuestos, useFacturacion } from '@hooks';
import { Divider, PieChart } from '@components/atoms';
import { WelcomeBoard } from '@components/molecules';
import { getColors } from '@helpers/utils';
import moment from 'moment';

const Dashboard = () => {
  const { pages: { customers: { dashboard: texts } } } = i18n;
  const { getPresupuestoCount, isPresupuestoLoading } = usePresupuestos();
  const { getFacturacionCount, isFacturacionLoading } = useFacturacion();

  const [presupuesto, setPresupuesto] = useState({ name: 'Presupuestos', data: null });
  const [facturacion, setFacturacion] = useState({ name: 'Informes', data: null });

  const [presupuestoColors, setPresupuestoColors] = useState([]);
  const [facturacionColors, setFacturacionColors] = useState([]);

  const [filters, setFilters] = useState({
    dateInit: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    all: true,
    archivados: false,
    generate: false,
  });

  useEffect(async () => {
    const result = await Promise.allSettled([getPresupuestoCount(filters), getFacturacionCount(filters)]);
    if (result.map((r) => r.status).includes('rejected')) return;
    setPresupuesto({ ...presupuesto, data: result[0].value });
    setFacturacion({ ...facturacion, data: result[1].value });
    setFilters({ ...filters, generate: false });
  }, []);

  useEffect(async () => {
    const availableColors = presupuesto.data ? presupuesto.data.items.map((item) => getColors.getColorPresupuesto(item.name)) : [];
    setPresupuestoColors(availableColors);
  }, [presupuesto]);

  useEffect(async () => {
    const availableColors = facturacion.data ? facturacion.data.items.map((item) => getColors.getColorFacturacion(item.id)) : [];
    setFacturacionColors(availableColors);
  }, [facturacion]);

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      { (isPresupuestoLoading || isFacturacionLoading)
        ? <LinearProgress />
        : (
          <>
            <WelcomeBoard
              facturacion={facturacion}
              presupuesto={presupuesto}
            />
            <Divider />
            <Grid container spacing={3} justifyContent="center">
              {presupuesto && presupuesto.data && <PieChart data={presupuesto} backgroundColor={presupuestoColors} />}
              {facturacion && facturacion.data && <PieChart data={facturacion} backgroundColor={facturacionColors} />}
            </Grid>
          </>
        )}
    </>
  );
};

export default Dashboard;
