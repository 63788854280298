const dasboard = {
  title: 'Dashboard',
  form: {
    title: 'Filtros',
    yearFrom: 'Año Desde',
    yearTo: 'Año Hasta',
    submit: 'Buscar',
    success: 'Datos actualizados correctamente',
    errorDate: 'El año desde no puede ser mayor al año hasta',
  },
};

export default dasboard;
