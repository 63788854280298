const facturacion = {
  title: 'Informes',
  add: 'Agregar Informe',
  list: {
    title: 'Informe',
    columns: {
      customer: 'Cliente',
      contact: 'Contacto',
      file: 'Archivo',
      number: 'Número',
      description: 'Descripción',
      state: 'Estado',
      createdBy: 'Creado por',
      createdAt: 'Fecha creación',
      sample: 'Identificación de Muestra',
    },
  },
  view: {
    subtitleData: 'Datos del informe',
    subtitleFacturacion: 'Datos Facturación',
    customer: 'Cliente',
    noContacts: 'Cliente no tiene contactos',
    contact: 'Contacto',
    presupuesto: 'Presupuesto',
    agreement: 'Tipo Acuerdo',
    statusAccept: 'Estado informe',
    accept: 'Aceptar Informe',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'Ingreso creado exitosamente',
    docFactura: 'Factura',
    docInforme: 'Informe',
    folio: 'Folio Factura',
    amount: 'Monto $',
    fechaExp: 'Fecha Expiración',
    state: 'Estado',
    download: 'Descargar',
  },
};

export default facturacion;
