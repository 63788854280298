import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { makeStyles } from '@material-ui/core/styles';
import { useFacturacion, useInformes } from '@hooks';
import {
  Grid,
  FormControl,
  TextField,
  LinearProgress,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  ButtonAdorned,
  Subtitle,
  MultiFileUpload,
  ChipStep,
  DatePicker,
  IconButton,
  DataGrid,
} from '@components/atoms';
import { Messaging } from '@components/molecules';
import {
  getCompleteName, getDate, getPriceFormatted, getStateFacturacion,
} from '@helpers/utils';
import constants from '@helpers/constants';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none',
    },
  },
}));

const View = ({ id, setExtraInfo }) => {
  const { getFiles: getFileInformeCliente, isInformeLoading, getFilesCert } = useInformes();
  const {
    getFacturacion,
    getFiles: getFilesFacturacion,
    editFacturacion,
    isFacturacionLoading,
  } = useFacturacion();
  const [facturacion, setFacturacion] = useState(null);
  const [message, setMessage] = useState('');
  const { pages: { customers: { facturacion: { view: texts } } } } = i18n;
  const [dataSelected, setDataSelected] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  const columns = [
    {
      field: 'file',
      headerAlign: 'center',
      headerName: 'Documento', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      valueFormatter: (value) => value.row.name,
    },
    {
      field: 'download',
      headerAlign: 'center',
      headerName: 'Descarga', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      renderCell: (value) => (
        <IconButton onClick={() => getURLPDF(value)}>
          <GetAppIcon />
        </IconButton>
      ),
    },
  ];

  const hanndleAccepted = async () => {
    await editFacturacion({ facturacion: { id }, accepted: true });
    history.push('/c/facturacion');
  };
  const refreshFacturacion = async () => {
    setExtraInfo([]);
    const serviceFacturacion = await getFacturacion({ id });
    const docInformeCliente = await getFileInformeCliente({
      customerId: serviceFacturacion?.contact?.customer?.id,
      facturacionId: id,
    });
    const docFactura = await getFilesFacturacion({
      customerId: serviceFacturacion?.contact?.customer?.id,
      facturacionId: id,
    });

    const updated = `Última actualización por: ${getCompleteName(serviceFacturacion.updatedBy)} | ${getDate(serviceFacturacion.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(serviceFacturacion.createdBy)} | ${getDate(serviceFacturacion.createdAt)}`;
    setExtraInfo([created || '', updated || '']);
    setFacturacion({
      ...serviceFacturacion,
      docInformeCliente,
      docFactura,
      expiredDate: serviceFacturacion.expiredDate?.substring(0, 10),
    });
  };

  const getSelectedData = (rows) => {
    if (rows.length === 0) return;
    if (rows.isSelected === true) {
      setDataSelected([...dataSelected, rows.data]);
    }
    if (rows.isSelected === false) {
      const newData = dataSelected.filter((item) => item.id !== rows.data.id);
      setDataSelected(newData);
    }
  };

  const handleClickMessage = async () => {
    if (message) {
      await editFacturacion({ facturacion: { id }, message });
      setMessage('');
      await refreshFacturacion();
    }
  };
  useEffect(async () => {
    await refreshFacturacion();
  }, []);

  const downloadFiles = async () => {
    if (dataSelected.length === 0) return;
    dataSelected.map(async (file) => {
      const fileURL = await getFilesCert({
        customerId: facturacion?.contact?.customer?.id,
        facturacionId: facturacion?.id,
        name: file.name,
      });
      if (fileURL) {
        const t = await fetch(fileURL);
        const b = await t.blob();
        const blobURL = URL.createObjectURL(b);
        const fileLink = document.createElement('a');
        fileLink.href = blobURL;

        // it forces the name of the downloaded file
        fileLink.download = file.name;

        // triggers the click event
        fileLink.click();

        // window.open(blobURL);
      }
      return fileURL;
    });
  };

  const getURLPDF = async (value) => {
    const fileURL = await getFilesCert({
      customerId: facturacion?.contact?.customer?.id,
      facturacionId: facturacion?.id,
      name: value?.row?.name,
    });

    if (fileURL) {
      const t = await fetch(fileURL);
      const b = await t.blob();
      const blobURL = URL.createObjectURL(b);
      const fileLink = document.createElement('a');
      fileLink.href = blobURL;
      window.open(blobURL);
    }
    return fileURL;
  };

  return (
    <>
      {(isFacturacionLoading || isInformeLoading) && <LinearProgress />}
      {!isFacturacionLoading && !isInformeLoading && facturacion && (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Messaging
            items={facturacion.messaging}
            state={facturacion.stateFacturacion}
            message={message}
            setMessage={setMessage}
            accepted={facturacion.accepted}
            handleClickMessage={handleClickMessage}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={1}
          justifyContent="center"
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2}>
            {texts.subtitleData && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id={texts.customer}
                  label={texts.customer}
                  variant="outlined"
                  defaultValue={facturacion?.contact.customer.name}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id={texts.contact}
                  label={texts.contact}
                  variant="outlined"
                  defaultValue={`${facturacion.contact.name} ${facturacion.contact.lastname}`}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.agreement}
                  variant="outlined"
                  disabled
                  value={facturacion.analisis?.agreement ? 'Con convenio' : 'Sin convenio'}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {(facturacion.analisis?.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            {facturacion.analisis.presupuesto?.number && (
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id={texts.presupuesto}
                  label={texts.presupuesto}
                  variant="outlined"
                  defaultValue={facturacion.analisis.presupuesto?.number}
                  disabled
                />
              </FormControl>
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.statusAccept}
                  variant="outlined"
                  disabled
                  value={facturacion && (facturacion.accepted ? 'Aceptado' : 'Sin Aceptar')}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {facturacion && (facturacion.accepted ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            { !isInformeLoading && facturacion.length !== 0 && facturacion?.stateFacturacion?.id !== '6166587fac44bed53cac8bfc'
            && (
              <Grid item xs={12} sm={6} md={4} alignContent="center">
                <FormControl fullWidth>
                  <ButtonAdorned
                    color="primary"
                    disabled={dataSelected.length === 0}
                    onClick={() => downloadFiles(dataSelected)}
                  >
                    {texts.download}
                  </ButtonAdorned>
                </FormControl>
              </Grid>
            )}
            { !isInformeLoading && facturacion.length !== 0 && facturacion?.stateFacturacion?.id !== '6166587fac44bed53cac8bfc'
            && (
              <Grid item xs={12}>
                <DataGrid
                  className={classes.root}
                  checkboxSelection
                  columns={columns}
                  key="id"
                  onRowSelected={(rowId) => getSelectedData(rowId)}
                  rows={facturacion?.docInformeCliente}
                />
              </Grid>
            )}
            {!isInformeLoading && facturacion.length !== 0 && facturacion?.stateFacturacion?.id === '6166587fac44bed53cac8bfc'
            && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Pago pendiente</AlertTitle>
                  Informe será liberado al momento de realizar el pago pendiente asociado.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleFacturacion}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id={texts.folio}
                  label={texts.folio}
                  variant="outlined"
                  defaultValue={facturacion.folio}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id={texts.amount}
                  label={texts.amount}
                  variant="outlined"
                  defaultValue={getPriceFormatted(facturacion.amount)}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <DatePicker
                  label={texts.fechaExp}
                  value={facturacion.expiredDate}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docFactura}
                  urls={facturacion.docFactura || []}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ alignSelf: 'center' }}>
              <FormControl variant="outlined" fullWidth>
                <ChipStep
                  label={facturacion.stateFacturacion.name}
                  backgroundColor={getStateFacturacion(facturacion.stateFacturacion.id).backgroundColor}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned
                  color="default"
                  onClick={() => history.push('/c/facturacion')}
                >
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              { facturacion && !facturacion.accepted && (
              <Grid item>
                <ButtonAdorned
                  color="primary"
                  onClick={hanndleAccepted}
                  disabled={facturacion.stateFacturacion?.id === constants.STATE_FACTURACION.PENDIENTE_PAGO.id}
                >
                  {texts.accept}
                </ButtonAdorned>
              </Grid>
              )}
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

View.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};
export default View;
